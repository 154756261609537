import React from 'react';
import PageHeader from '../../../PageHeader';
import data from '../../../../data/childrenOCD';

import styles from './childrenOCD.module.scss';

const ChildrenOCD = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497222290/playTherapyBG.jpg';

  return (
    <main>
      <PageHeader pageCategory="Children" pageName="OCD Treatment" headerImage={background} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Overview</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.overview }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>What to Expect in Treatment</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.details }} />
      </div>
    </main>
  );
};

export default ChildrenOCD;
