import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import ChildrenOCD from '../../components/Relationships/components/ChildrenOCD';

export default () => (
  <>
    <SEO
      title="Treatment for Children with OCD | Mindset Family Therapy"
      description="If you’re a parent, how can you ensure that your child receives the right treatment for their OCD? If you suspect your child suffers from OCD, it is suggested that you request the school psychologist to administer the Children’s Yale-Brown Obsessive Compulsive Scale (CY-BOCS), and/or have a qualified therapist administer the test. When it comes to treating pediatric OCD, research indicates that CBT with the focus on parents and family involvement provide the most positive outcomes."
      pathname="/relationships/ocd-in-children"
      article
    />
    <Layout>
      <ChildrenOCD />
    </Layout>
  </>
);
